import React, { useState } from 'react'
import tw, { styled } from 'twin.macro'
import { useForm } from 'react-hook-form'
import WatchElementAnimate from '../../components/watch-element-animate'
import { QForm, SmallSolidTurquoiseInput } from '../../utils/styles'
import axios from 'axios'
import HexagonCheck from '../../images/Hexagon-Check.svg'
import Img from 'gatsby-image'
const AIWorkWrapper = styled.div``

const AIWork = ({ ai, items, flow, type, i18n }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const [requested, setRequested] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const onSubmit = async (data) => {
    setSubmitting(true)
    const response = await axios.post('/.netlify/functions/meeting', data)
    if (response.data.hasOwnProperty('success') && response.data.success) {
      setRequested(true)
    }
    setSubmitting(false)
  }
  const styles = {
    row: tw``,
    reverse: tw`md:col-start-1 md:row-start-1`,
  }
  return (
    <AIWorkWrapper tw="container pb-20 pt-8 text-center md:pb-28 md:pt-14">
      <div tw="grid md:grid-cols-2 gap-x-56 gap-y-8 items-center">
        <WatchElementAnimate
          className={'.index__ai__animated__left'}
          animateName={
            'reverse' === flow ? 'animate__fadeInRight' : 'animate__fadeInLeft'
          }>
          <div
            tw="flex items-center text-center md:text-left"
            className="index__ai__animated__left">
            <div>
              {type != null ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: ai.headline.html,
                  }}
                  tw="text-navy"></div>
              ) : (
                <h2 tw="text-navy">{ai.headline.text}</h2>
              )}
              <p tw="text-xl text-ocean mt-4">{ai.subheadline.text}</p>
              <div tw="flex flex-col gap-6 mt-6">
                {items.map((item, key) => {
                  return (
                    <div tw="flex flex-col md:flex-row gap-8" key={key}>
                      <div tw="w-14 m-auto md:mx-0">
                        <Img fluid={item.icon.fluid} />
                      </div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.description.html,
                        }}
                        tw="text-ocean flex items-center flex-col md:flex-row text-xl m-auto md:m-0"
                        className="item-desc">
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </WatchElementAnimate>
        <WatchElementAnimate
          className={'.index__ai__animated__right'}
          animateName={
            'reverse' === flow ? 'animate__fadeInLeft' : 'animate__fadeInRight'
          }>
          {requested ? (
            <div className="index__ai__animated__right">
              <h2 tw="text-navy">{i18n.form.message.headline}</h2>
              <p tw="text-xl text-ocean mt-4">{i18n.form.message.desc}</p>
            </div>
          ) : (
            <QForm css={styles[flow]} className="index__ai__animated__right">
              <form onSubmit={handleSubmit(onSubmit)} tw="flex flex-col">
                <h3>{i18n.form.title}</h3>
                <input
                  {...register('firstName', { required: true })}
                  placeholder={i18n.form.meeting.first_name}
                  className="q-input"
                />
                {errors.firstName && (
                  <span tw="mb-2 text-red">{i18n.form.error.first_name}</span>
                )}
                <input
                  {...register('lastName', { required: true })}
                  placeholder={i18n.form.meeting.last_name}
                  className="q-input"
                />
                {errors.lastName && (
                  <span tw="mb-2 text-red">{i18n.form.error.last_name}</span>
                )}
                <input
                  {...register('workEmail', {
                    required: true,
                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  })}
                  placeholder={i18n.form.meeting.work_email}
                  className="q-input"
                />
                {errors.workEmail?.type === 'required' && (
                  <span tw="mb-2 text-red">{i18n.form.error.work_email}</span>
                )}
                {errors.workEmail?.type === 'pattern' && (
                  <span tw="mb-2 text-red">{i18n.form.error.valid_email}</span>
                )}
                <input
                  {...register('companyName', { required: false })}
                  placeholder={i18n.form.meeting.company_name}
                  className="q-input"
                />
                <input
                  {...register('jobTitle', { required: false })}
                  placeholder={i18n.form.meeting.job_title}
                  className="q-input"
                />
                <SmallSolidTurquoiseInput
                  type="submit"
                  tw="relative"
                  className={submitting ? 'button is-loading' : 'button'}
                  disabled={submitting}>
                  {i18n.form.meeting.button}
                </SmallSolidTurquoiseInput>
                <span tw="mt-6 text-sm">{i18n.form.notice}</span>
              </form>
            </QForm>
          )}
        </WatchElementAnimate>
      </div>
    </AIWorkWrapper>
  )
}
export default AIWork
